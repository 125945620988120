import { useState, useRef, useEffect } from "react";
import { TextField, Button, IconButton, Box } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import "./ChatBot.css";

const ChatBot = () => {
  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeStamps, setTimeStamps] = useState([]); // Stores elapsed times for each message
  const chatContainerRef = useRef(null);

  const toggleChat = () => setChatOpen(!chatOpen);

  const sendMessage = async (message) => {
    const newMessage = {
      sender: "user",
      text: message,
      timestamp: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Set loading to true
    setLoading(true);

    try {
      const response = await axios.get(
        `https://aceiot.citttech.com/python/intents?message=${message}`
      );

      const botResponse = {
        sender: "bot",
        text: response.status === 200 ? response.data.message : "Invalid data",
        timestamp: new Date(),
      };

      // Add bot response after 3 seconds
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.error("Error fetching intent response:", error);

      // Show error message after 3 seconds
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: "Sorry, I could not retrieve intent information.",
            timestamp: new Date(),
          },
        ]);
        setLoading(false);
      }, 2000);
    }
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();
    if (chatMessage.trim()) {
      sendMessage(chatMessage);
      setChatMessage("");
    }
  };

  const calculateElapsedTime = () => {
    const now = new Date();
    const updatedTimeStamps = messages.map((msg) => {
      const elapsedMinutes = Math.floor(
        (now.getTime() - new Date(msg.timestamp).getTime()) / (1000 * 60)
      );
      return elapsedMinutes > 0 ? `${elapsedMinutes} min(s) ago` : "Just now";
    });
    setTimeStamps(updatedTimeStamps);
  };

  // Dynamically update elapsed time every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      calculateElapsedTime();
    }, 2000); // Update every 20 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [messages]);

  // Scroll to bottom on new message
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
    calculateElapsedTime(); // Ensure times update on new message
  }, [messages]);

  return (
    <>
      {/* Floating Chat Icon */}
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1000,
        }}
      >
        <IconButton
          color="primary"
          onClick={toggleChat}
          sx={{
            backgroundColor: "#007bff",
            color: "#fff",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            "&:hover": { backgroundColor: "#0056b3" },
          }}
        >
          <ChatIcon />
        </IconButton>
      </Box>

      {/* Chat Box */}
      {chatOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 80,
            right: 20,
            width: { xs: "90vw", sm: 350, md: 400 },
            padding: 2,
            backgroundColor: "#fff",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
        >
          {/* Chat Header */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: "10px" }}
            className="bg-primary"
          >
            <h4 style={{ margin: 0, marginLeft: "50px" }}>
              Live with <span className="text-warning">ACEIoT</span>
            </h4>
            <IconButton onClick={toggleChat}>
              <CloseIcon className="text-danger" />
            </IconButton>
          </Box>

          {/* Chat Message List */}
          <div
            ref={chatContainerRef}
            style={{
              height: "200px",
              overflowY: "auto",
              padding: "10px",
              border: "1px solid #ddd",
            }}
          >
            {messages.map((msg, index) => (
              <div key={index} style={{ margin: "10px 0" }}>
                <div
                  style={{
                    textAlign: msg.sender === "user" ? "right" : "left",
                  }}
                >
                  <p
                    style={{
                      backgroundColor:
                        msg.sender === "user" ? "#f1f1f1" : "#d1e7dd",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    {msg.text}
                  </p>
                </div>
                <div
                  style={{
                    textAlign: msg.sender === "user" ? "right" : "left",
                    fontSize: "0.8em",
                    color: "#888",
                  }}
                >
                  {timeStamps[index] || "Just now"}
                </div>
              </div>
            ))}

            {/* Display loading dots when waiting for bot response */}
            {loading && (
              <div style={{ textAlign: "left", marginTop: "10px" }}>
                <div className="dot-loading">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </div>
              </div>
            )}
          </div>

          {/* Chat Input */}
          <form onSubmit={handleChatSubmit}>
            <TextField
              fullWidth
              placeholder="Type your message..."
              value={chatMessage}
              onChange={(e) => setChatMessage(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Send
            </Button>
          </form>
        </Box>
      )}
    </>
  );
};

export default ChatBot;
